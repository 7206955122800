import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useAppContext } from '../../context/ContextoApp';
import { useTheme } from '../../context/ContextoTema';
import { useErrorHandler } from '../../utils/manejadorErrores';
import api from '../../services/api';
import { Tarjeta, TarjetaEncabezado, TarjetaContenido } from '../ui/tarjeta';
import Boton from '../ui/boton';
import { Seleccion, SeleccionTrigger, SeleccionValue, SeleccionContent, SeleccionItem } from '../ui/seleccion';
import './FormularioAutorizacion.css';

const EsquemaAutorizacion = Yup.object().shape({
  idPaciente: Yup.string()
    .required('El número de identificación del paciente es requerido'),
  pin: Yup.string()
    .required('El PIN de autorización es requerido')
    .min(6, 'El PIN debe tener al menos 6 caracteres')
    .max(6, 'El PIN no debe exceder 6 caracteres'),
  tipoCita: Yup.string()
    .required('El tipo de cita es requerido'),
});

const FormularioAutorizacion = () => {
  const [resultadoVerificacion, setResultadoVerificacion] = useState(null);
  const { dispatch } = useAppContext();
  const { isDarkMode } = useTheme();
  const manejarError = useErrorHandler();

  const manejarEnvio = async (valores, { setSubmitting }) => {
    try {
      const respuesta = await api.post('/autorizar/', valores);
      setResultadoVerificacion(respuesta.data);
      dispatch({ type: 'AGREGAR_NOTIFICACION', payload: 'Verificación completada' });
    } catch (error) {
      manejarError(error);
      setResultadoVerificacion({ estado: 'Error', mensaje: 'No se pudo verificar la autorización' });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className={`formulario-autorizacion ${isDarkMode ? 'oscuro' : ''}`}>
      <h1>Formulario de Autorización</h1>
      <div className="contenedor-formulario">
        <Tarjeta className="tarjeta-formulario">
          <TarjetaContenido>
            <Formik
              initialValues={{ idPaciente: '', pin: '', tipoCita: '' }}
              validationSchema={EsquemaAutorizacion}
              onSubmit={manejarEnvio}
            >
              {({ isSubmitting, setFieldValue }) => (
                <Form>
                  <div className="grupo-formulario">
                    <label htmlFor="idPaciente">Número de Identificación del Paciente:</label>
                    <Field type="text" id="idPaciente" name="idPaciente" />
                    <ErrorMessage name="idPaciente" component="div" className="mensaje-error" />
                  </div>
                  
                  <div className="grupo-formulario">
                    <label htmlFor="pin">PIN de Autorización:</label>
                    <Field type="password" id="pin" name="pin" />
                    <ErrorMessage name="pin" component="div" className="mensaje-error" />
                  </div>
                  
                  <div className="grupo-formulario">
                    <label htmlFor="tipoCita">Tipo de Cita:</label>
                    <Seleccion
                      onValueChange={(valor) => setFieldValue('tipoCita', valor)}
                      name="tipoCita"
                    >
                      <SeleccionTrigger>
                        <SeleccionValue placeholder="Seleccione el tipo de cita" />
                      </SeleccionTrigger>
                      <SeleccionContent>
                        <SeleccionItem value="general">General</SeleccionItem>
                        <SeleccionItem value="especialista">Especialista</SeleccionItem>
                        <SeleccionItem value="seguimiento">Seguimiento</SeleccionItem>
                      </SeleccionContent>
                    </Seleccion>
                    <ErrorMessage name="tipoCita" component="div" className="mensaje-error" />
                  </div>

                  <div className="acciones-formulario">
                    <Boton type="submit" disabled={isSubmitting}>
                      {isSubmitting ? 'Verificando...' : 'Verificar'}
                    </Boton>
                  </div>
                </Form>
              )}
            </Formik>
          </TarjetaContenido>
        </Tarjeta>

        <Tarjeta className="tarjeta-resultado">
          <TarjetaEncabezado>Resultado de Verificación</TarjetaEncabezado>
          <TarjetaContenido>
            {resultadoVerificacion ? (
              <div className={`resultado-verificacion ${resultadoVerificacion.estado.toLowerCase()}`}>
                <p>Estado: {resultadoVerificacion.estado}</p>
                <p>{resultadoVerificacion.mensaje}</p>
              </div>
            ) : (
              <p>Pendiente</p>
            )}
          </TarjetaContenido>
        </Tarjeta>
      </div>

      <div className="enlaces-ayuda">
        <button onClick={() => console.log('Solicitar nuevo PIN')} className="enlace-texto">
          ¿Olvidó su PIN? Solicite uno nuevo
        </button>
        <button onClick={() => console.log('Ayuda con autorización')} className="enlace-texto">
          Ayuda con el proceso de autorización
        </button>
      </div>
    </div>
  );
};

export default FormularioAutorizacion;
