import React from 'react';
import { useTheme } from '../../context/ContextoTema';
import './boton.css';

const Boton = React.forwardRef(({ 
  children, 
  variante = 'primario', 
  tamaño = 'medio', 
  anchoCompleto = false,
  deshabilitado = false,
  className = '', 
  onClick,
  tipo = 'button',
  ...props 
}, ref) => {
  const { isDarkMode } = useTheme();

  const claseBase = 'boton';
  const claseVariante = `boton-${variante}`;
  const claseTamaño = `boton-${tamaño}`;
  const claseAncho = anchoCompleto ? 'boton-ancho-completo' : '';
  const claseTema = isDarkMode ? 'oscuro' : '';
  const claseDeshabilitado = deshabilitado ? 'boton-deshabilitado' : '';

  const claseBoton = `
    ${claseBase} 
    ${claseVariante} 
    ${claseTamaño} 
    ${claseAncho} 
    ${claseTema} 
    ${claseDeshabilitado} 
    ${className}
  `.trim();

  return (
    <button
      ref={ref}
      className={claseBoton}
      onClick={deshabilitado ? undefined : onClick}
      disabled={deshabilitado}
      type={tipo}
      {...props}
    >
      {children}
    </button>
  );
});

Boton.displayName = 'Boton';

export default Boton;
