import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAppContext } from '../context/ContextoApp';
import { useTheme } from '../context/ContextoTema';
import CambiarTema from './CambiarTema';
import Boton from './ui/boton';
import './BarraNavegacion.css';

const BarraNavegacion = React.memo(() => {
  const location = useLocation();
  const navigate = useNavigate();
  const { state, dispatch } = useAppContext();
  const { isDarkMode } = useTheme();

  const handleCerrarSesion = () => {
    dispatch({ type: 'CERRAR_SESION' });
    navigate('/inicio-sesion');
  };

  return (
    <nav className={`barra-navegacion ${isDarkMode ? 'oscuro' : ''}`}>
      <div className="contenedor-barra-navegacion">
        <Link to="/" className="logo-barra-navegacion">
          CitaMédica.co
        </Link>
        <ul className="menu-navegacion">
          <li className="item-navegacion">
            <Link to="/" className={`enlace-navegacion ${location.pathname === '/' ? 'activo' : ''}`}>
              Inicio
            </Link>
          </li>
          {state.estaAutenticado ? (
            <>
              <li className="item-navegacion">
                <Link to="/panel-control" className={`enlace-navegacion ${location.pathname === '/panel-control' ? 'activo' : ''}`}>
                  Panel de Control
                </Link>
              </li>
              <li className="item-navegacion">
                <Link to="/agendar" className={`enlace-navegacion ${location.pathname === '/agendar' ? 'activo' : ''}`}>
                  Agendar Cita
                </Link>
              </li>
              <li className="item-navegacion">
                <Boton onClick={handleCerrarSesion} variante="contorno">
                  Cerrar Sesión
                </Boton>
              </li>
            </>
          ) : (
            <>
              <li className="item-navegacion">
                <Link to="/inicio-sesion" className={`enlace-navegacion ${location.pathname === '/inicio-sesion' ? 'activo' : ''}`}>
                  Iniciar Sesión
                </Link>
              </li>
              <li className="item-navegacion">
                <Link to="/registro" className={`enlace-navegacion ${location.pathname === '/registro' ? 'activo' : ''}`}>
                  Registrarse
                </Link>
              </li>
            </>
          )}
        </ul>
        <CambiarTema />
      </div>
    </nav>
  );
});

export default BarraNavegacion;
