import React, { createContext, useContext, useState } from 'react';

const ContextoTema = createContext();

export const ProveedorTema = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  const cambiarTema = () => {
    setIsDarkMode((prevMode) => !prevMode);
  };

  return (
    <ContextoTema.Provider value={{ isDarkMode, cambiarTema }}>
      {children}
    </ContextoTema.Provider>
  );
};

export const useTheme = () => useContext(ContextoTema);
