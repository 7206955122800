import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../context/ContextoApp';
import { useTheme } from '../../context/ContextoTema';
import { useErrorHandler } from '../../utils/manejadorErrores';
import api from '../../services/api';
import { Tarjeta, TarjetaEncabezado, TarjetaContenido } from '../ui/tarjeta';
import Boton from '../ui/boton';
import './PanelDoctor.css';

const PanelDoctor = () => {
  const [citas, setCitas] = useState([]);
  const [estadisticas, setEstadisticas] = useState({ totalPacientes: 0, citasHoy: 0, citasCompletadas: 0 });
  const navigate = useNavigate();
  const { state } = useAppContext();
  const { isDarkMode } = useTheme();
  const manejarError = useErrorHandler();

  useEffect(() => {
    const obtenerDatosPanelControl = async () => {
      try {
        const [citasRespuesta, estadisticasRespuesta] = await Promise.all([
          api.get('doctor/citas/'),
          api.get('doctor/estadisticas/')
        ]);
        setCitas(citasRespuesta.data);
        setEstadisticas(estadisticasRespuesta.data);
      } catch (error) {
        manejarError(error);
      }
    };

    obtenerDatosPanelControl();
  }, [manejarError]);

  const verDetallesCita = (citaId) => {
    navigate(`/cita/${citaId}`);
  };

  return (
    <div className={`panel-doctor ${isDarkMode ? 'oscuro' : ''}`}>
      <h1>Bienvenido, Dr. {state.usuario?.nombre} {state.usuario?.apellido}</h1>
      
      <div className="contenedor-estadisticas">
        <Tarjeta>
          <TarjetaEncabezado>Total de Pacientes</TarjetaEncabezado>
          <TarjetaContenido>{estadisticas.totalPacientes}</TarjetaContenido>
        </Tarjeta>
        <Tarjeta>
          <TarjetaEncabezado>Citas Hoy</TarjetaEncabezado>
          <TarjetaContenido>{estadisticas.citasHoy}</TarjetaContenido>
        </Tarjeta>
        <Tarjeta>
          <TarjetaEncabezado>Citas Completadas</TarjetaEncabezado>
          <TarjetaContenido>{estadisticas.citasCompletadas}</TarjetaContenido>
        </Tarjeta>
      </div>

      <h2>Próximas Citas</h2>
      <div className="lista-citas">
        {citas.map((cita) => (
          <Tarjeta key={cita.id} className="item-cita">
            <TarjetaEncabezado>
              {cita.paciente.usuario.nombre} {cita.paciente.usuario.apellido}
            </TarjetaEncabezado>
            <TarjetaContenido>
              <p>Fecha y Hora: {new Date(cita.fecha_hora).toLocaleString()}</p>
              <p>Estado: {cita.estado}</p>
              <Boton onClick={() => verDetallesCita(cita.id)}>
                Ver Detalles
              </Boton>
            </TarjetaContenido>
          </Tarjeta>
        ))}
      </div>
      <Boton onClick={() => navigate('/doctor/agenda')} className="boton-ver-agenda">
        Ver Agenda Completa
      </Boton>
    </div>
  );
};

export default PanelDoctor;
