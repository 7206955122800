import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'https://crm.citamedica.co/api/';

const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true, // Necesario para enviar cookies en solicitudes cross-origin
});

// Interceptor para añadir el token a las solicitudes
api.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('token_acceso');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Interceptor para manejar respuestas y errores
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const solicitudOriginal = error.config;

    // Si el error es 401 y no hemos intentado renovar el token
    if (error.response.status === 401 && !solicitudOriginal._retry) {
      solicitudOriginal._retry = true;

      try {
        // Intenta renovar el token
        const tokenRenovacion = localStorage.getItem('token_renovacion');
        const respuesta = await axios.post(`${API_URL}token/renovar/`, { refresh: tokenRenovacion });
        const { access } = respuesta.data;

        localStorage.setItem('token_acceso', access);

        // Reintenta la solicitud original con el nuevo token
        solicitudOriginal.headers['Authorization'] = `Bearer ${access}`;
        return api(solicitudOriginal);
      } catch (errorRenovacion) {
        // Si no se puede renovar el token, redirige al inicio de sesión
        localStorage.removeItem('token_acceso');
        localStorage.removeItem('token_renovacion');
        window.location.href = '/inicio-sesion';
        return Promise.reject(errorRenovacion);
      }
    }

    return Promise.reject(error);
  }
);

export default api;
