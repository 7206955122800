import React, { Suspense } from 'react';
import * as Sentry from "@sentry/react";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cargando from './components/Cargando';
import ContenidoApp from './ContenidoApp';
import './styles/transiciones.css';

const App = () => {
  return (
    <Suspense fallback={<Cargando />}>
      <div className="App">
        <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} />
        <ContenidoApp />
      </div>
    </Suspense>
  );
};

export default Sentry.withProfiler(App);
