import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Sentry from "@sentry/react";
import { useAppContext } from '../context/ContextoApp';

export const useErrorHandler = () => {
  const { dispatch } = useAppContext();
  const navigate = useNavigate();

  const manejarError = useCallback((error) => {
    console.error('Error:', error);

    let mensajeError = 'Ha ocurrido un error. Por favor, intenta de nuevo más tarde.';
    let debeRedirigir = false;

    if (error.response) {
      // El servidor respondió con un código de estado fuera del rango de 2xx
      switch (error.response.status) {
        case 400:
          mensajeError = 'Solicitud incorrecta. Por favor, verifica los datos ingresados.';
          break;
        case 401:
          mensajeError = 'Sesión expirada. Por favor, inicia sesión de nuevo.';
          dispatch({ type: 'CERRAR_SESION' });
          debeRedirigir = true;
          break;
        case 403:
          mensajeError = 'No tienes permiso para realizar esta acción.';
          break;
        case 404:
          mensajeError = 'El recurso solicitado no fue encontrado.';
          break;
        case 500:
          mensajeError = 'Error interno del servidor. Por favor, intenta más tarde.';
          break;
        default:
          if (error.response.data && error.response.data.mensaje) {
            mensajeError = error.response.data.mensaje;
          }
      }
    } else if (error.request) {
      // La solicitud se hizo pero no se recibió respuesta
      mensajeError = 'No se pudo conectar con el servidor. Por favor, verifica tu conexión a internet.';
    } else {
      // Algo sucedió al configurar la solicitud que provocó un error
      mensajeError = error.message;
    }

    // Enviar el error a Sentry
    Sentry.captureException(error, {
      extra: {
        mensajeError,
      },
    });

    // Mostrar notificación de error
    dispatch({ type: 'AGREGAR_NOTIFICACION', payload: { tipo: 'error', mensaje: mensajeError } });

    // Redirigir si es necesario
    if (debeRedirigir) {
      navigate('/inicio-sesion');
    }

    return mensajeError;
  }, [dispatch, navigate]);

  return manejarError;
};
