import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../context/ContextoApp';
import { useTheme } from '../../context/ContextoTema';
import { useErrorHandler } from '../../utils/manejadorErrores';
import api from '../../services/api';
import { Tarjeta, TarjetaEncabezado, TarjetaContenido } from '../ui/tarjeta';
import Boton from '../ui/boton';
import './PanelPaciente.css';

const PanelPaciente = () => {
  const [citas, setCitas] = useState([]);
  const [estadisticas, setEstadisticas] = useState({ citasTotales: 0, citasCompletadas: 0 });
  const navigate = useNavigate();
  const { state, dispatch } = useAppContext();
  const { isDarkMode } = useTheme();
  const manejarError = useErrorHandler();

  useEffect(() => {
    const obtenerDatosPanelControl = async () => {
      try {
        const [citasRespuesta, estadisticasRespuesta] = await Promise.all([
          api.get('/citas/'),
          api.get('/paciente/estadisticas/')
        ]);
        setCitas(citasRespuesta.data);
        setEstadisticas(estadisticasRespuesta.data);
      } catch (error) {
        manejarError(error);
      }
    };

    obtenerDatosPanelControl();
  }, [manejarError]);

  const cancelarCita = async (citaId) => {
    try {
      await api.patch(`/citas/${citaId}/`, { estado: 'CANCELADA' });
      dispatch({ type: 'AGREGAR_NOTIFICACION', payload: 'Cita cancelada correctamente.' });
      setCitas(citas.filter(cita => cita.id !== citaId));
    } catch (error) {
      manejarError(error);
    }
  };

  return (
    <div className={`panel-paciente ${isDarkMode ? 'oscuro' : ''}`}>
      <h1>Bienvenido, {state.usuario?.nombre}</h1>
      
      <div className="contenedor-estadisticas">
        <Tarjeta>
          <TarjetaEncabezado>Total de Citas</TarjetaEncabezado>
          <TarjetaContenido>{estadisticas.citasTotales}</TarjetaContenido>
        </Tarjeta>
        <Tarjeta>
          <TarjetaEncabezado>Citas Completadas</TarjetaEncabezado>
          <TarjetaContenido>{estadisticas.citasCompletadas}</TarjetaContenido>
        </Tarjeta>
      </div>

      <h2>Tus Próximas Citas</h2>
      <div className="lista-citas">
        {citas.map((cita) => (
          <Tarjeta key={cita.id} className="item-cita">
            <TarjetaEncabezado>
              Dr. {cita.doctor.usuario.nombre} {cita.doctor.usuario.apellido}
            </TarjetaEncabezado>
            <TarjetaContenido>
              <p>Fecha y Hora: {new Date(cita.fecha_hora).toLocaleString()}</p>
              <p>Estado: {cita.estado}</p>
              {cita.estado === 'PENDIENTE' && (
                <Boton 
                  onClick={() => cancelarCita(cita.id)}
                  variante="destructivo"
                >
                  Cancelar Cita
                </Boton>
              )}
            </TarjetaContenido>
          </Tarjeta>
        ))}
      </div>
      <Boton onClick={() => navigate('/agendar/')} className="boton-agendar">
        Agendar Nueva Cita
      </Boton>
    </div>
  );
};

export default PanelPaciente;
