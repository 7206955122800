import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useAppContext } from '../context/ContextoApp';
import { useTheme } from '../context/ContextoTema';
import { useErrorHandler } from '../utils/manejadorErrores';
import { sanitizarEntrada } from '../utils/sanitizador';
import api from '../services/api';
import BarraNavegacion from '../components/BarraNavegacion';
import ImagenOptimizada from '../components/ImagenOptimizada';
import Boton from '../components/ui/boton';
// import Calendario from '../components/ui/calendario';
import './PantallaAgendarCita.css';

const EsquemaAgendarCita = Yup.object().shape({
  doctor: Yup.string().required('Por favor seleccione un médico'),
  fechaHora: Yup.date().required('Por favor seleccione una fecha y hora').min(new Date(), 'La fecha debe ser en el futuro')
});

const PantallaAgendarCita = () => {
  const location = useLocation();
  const fechaInicial = location.state?.fechaSeleccionada || new Date();

  const [doctores, setDoctores] = useState([]);
  const [paciente, setPaciente] = useState(null);
  const [esConfidencial, setEsConfidencial] = useState(false);
  const navigate = useNavigate();
  const { dispatch } = useAppContext();
  const { isDarkMode } = useTheme();
  const manejarError = useErrorHandler();

  const obtenerDoctores = useCallback(async () => {
    try {
      const respuesta = await api.get('/doctores/');
      setDoctores(respuesta.data);
    } catch (error) {
      manejarError(error);
    }
  }, [manejarError]);

  const obtenerPaciente = useCallback(async () => {
    try {
      const respuesta = await api.get('/pacientes/actual/');
      setPaciente(respuesta.data);
    } catch (error) {
      manejarError(error);
    }
  }, [manejarError]);

  useEffect(() => {
    obtenerDoctores();
    obtenerPaciente();
  }, [obtenerDoctores, obtenerPaciente]);

  const manejarEnvio = async (valores, { setSubmitting }) => {
    try {
      const valoresSanitizados = {
        doctor: sanitizarEntrada(valores.doctor),
        fecha_hora: valores.fechaHora,
        estado: 'PENDIENTE',
        es_confidencial: esConfidencial
      };
      await api.post('/cita/', valoresSanitizados);
      dispatch({ type: 'AGREGAR_NOTIFICACION', payload: 'Cita agendada correctamente.' });
      navigate('/panel-control');
    } catch (error) {
      manejarError(error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className={`pantalla-agendar-cita ${isDarkMode ? 'oscuro' : ''}`}>
      <BarraNavegacion />
      <div className="contenedor-agendar-cita">
        <h2>Agendar Cita</h2>
        <ImagenOptimizada src="/imagenes/agendar-cita.jpg" alt="Agendar cita" className="imagen-encabezado" />
        <Formik
          initialValues={{ doctor: '', fechaHora: fechaInicial.toISOString().slice(0, 16) }}
          validationSchema={EsquemaAgendarCita}
          onSubmit={manejarEnvio}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="grupo-formulario">
                <label htmlFor="doctor">Selecciona un médico:</label>
                <Field as="select" id="doctor" name="doctor">
                  <option value="">Selecciona un médico</option>
                  {doctores.map((doctor) => (
                    <option key={doctor.id} value={doctor.id}>
                      Dr. {doctor.usuario.nombre} {doctor.usuario.apellido} - {doctor.especialidad}
                    </option>
                  ))}
                </Field>
                <ErrorMessage name="doctor" component="div" className="mensaje-error" />
              </div>
              <div className="grupo-formulario">
                <label htmlFor="fechaHora">Fecha y Hora:</label>
                <Field type="datetime-local" id="fechaHora" name="fechaHora" />
                <ErrorMessage name="fechaHora" component="div" className="mensaje-error" />
              </div>
              {paciente && paciente.grupo_edad === '15-17' && (
                <div className="grupo-formulario">
                  <label>
                    <input
                      type="checkbox"
                      checked={esConfidencial}
                      onChange={(e) => setEsConfidencial(e.target.checked)}
                    />
                    Marcar cita como confidencial
                  </label>
                </div>
              )}
              <Boton type="submit" disabled={isSubmitting}>
                {isSubmitting ? 'Agendando...' : 'Agendar Cita'}
              </Boton>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default PantallaAgendarCita;
