import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../context/ContextoApp';
import { useTheme } from '../context/ContextoTema';
import { useErrorHandler } from '../utils/manejadorErrores';
import api from '../services/api';
import BarraNavegacion from '../components/BarraNavegacion';
import { Tarjeta, TarjetaEncabezado, TarjetaContenido } from '../components/ui/tarjeta';
import Boton from '../components/ui/boton';
import './PantallaPanel.css';

const PantallaPanel = () => {
  const [datosPanelControl, setDatosPanelControl] = useState(null);
  const navigate = useNavigate();
  const { state } = useAppContext();
  const { isDarkMode } = useTheme();
  const manejarError = useErrorHandler();

  useEffect(() => {
    const obtenerDatosPanelControl = async () => {
      try {
        const respuesta = await api.get('/panel-control/');
        setDatosPanelControl(respuesta.data);
      } catch (error) {
        manejarError(error);
      }
    };

    obtenerDatosPanelControl();
  }, [manejarError]);

  const renderizarContenidoPorRol = () => {
    if (!datosPanelControl) return null;

    switch (state.usuario.rol) {
      case 'paciente':
        return (
          <>
            <Tarjeta>
              <TarjetaEncabezado>Próxima Cita</TarjetaEncabezado>
              <TarjetaContenido>
                {datosPanelControl.proximaCita ? (
                  <p>{new Date(datosPanelControl.proximaCita.fecha).toLocaleString()}</p>
                ) : (
                  <p>No tienes citas programadas</p>
                )}
              </TarjetaContenido>
            </Tarjeta>
            <Boton onClick={() => navigate('/agendar')}>Agendar Nueva Cita</Boton>
          </>
        );
      case 'medico':
        return (
          <>
            <Tarjeta>
              <TarjetaEncabezado>Citas de Hoy</TarjetaEncabezado>
              <TarjetaContenido>
                <p>{datosPanelControl.citasHoy} citas programadas</p>
              </TarjetaContenido>
            </Tarjeta>
            <Boton onClick={() => navigate('/agenda')}>Ver Agenda Completa</Boton>
          </>
        );
      // Agregar más casos según sea necesario
      default:
        return <p>Bienvenido al panel de control</p>;
    }
  };

  return (
    <div className={`pantalla-panel ${isDarkMode ? 'oscuro' : ''}`}>
      <BarraNavegacion />
      <h1>Panel de Control</h1>
      {renderizarContenidoPorRol()}
    </div>
  );
};

export default PantallaPanel;
