import React from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '../context/ContextoTema';
import { useAppContext } from '../context/ContextoApp';
import BarraNavegacion from '../components/BarraNavegacion';
import ImagenOptimizada from '../components/ImagenOptimizada';
import Boton from '../components/ui/boton';
import { Tarjeta, TarjetaEncabezado, TarjetaContenido } from '../components/ui/tarjeta';
import './PantallaInicio.css';

const PantallaInicio = () => {
  const { isDarkMode } = useTheme();
  const { state } = useAppContext();

  const caracteristicas = [
    { icono: 'heartbeat', titulo: 'Conexión Inmediata', descripcion: 'Accede rápidamente a profesionales de la salud de confianza.' },
    { icono: 'comments', titulo: 'Atención Personalizada', descripcion: 'Recibe asesoría y soporte en cada paso del proceso.' },
    { icono: 'shield-alt', titulo: 'Seguridad y Confidencialidad', descripcion: 'Tus datos están protegidos con los más altos estándares.' },
  ];

  return (
    <div className={`pantalla-inicio ${isDarkMode ? 'oscuro' : ''}`}>
      <BarraNavegacion />
      <main className="contenido-principal">
        <section className="seccion-hero">
          <div className="contenido-hero">
            <h1>Bienvenido a CitaMédica.co</h1>
            <p>Tu puerta de acceso a una vida más saludable y feliz.</p>
            {state.estaAutenticado ? (
              <Link to="/agendar" className="boton-cta">
                <Boton>Agendar Cita Ahora</Boton>
              </Link>
            ) : (
              <Link to="/registro" className="boton-cta">
                <Boton>Regístrate Gratis</Boton>
              </Link>
            )}
          </div>
          <ImagenOptimizada
            src="./imagenes/hero-image.jpg"
            alt="Familia sonriendo en una consulta médica"
            className="imagen-hero"
          />
        </section>
        <section className="seccion-caracteristicas">
          {caracteristicas.map((caracteristica, index) => (
            <Tarjeta key={index} className="tarjeta-caracteristica">
              <TarjetaEncabezado>
                <i className={`fas fa-${caracteristica.icono}`}></i>
                <h2>{caracteristica.titulo}</h2>
              </TarjetaEncabezado>
              <TarjetaContenido>
                <p>{caracteristica.descripcion}</p>
              </TarjetaContenido>
            </Tarjeta>
          ))}
        </section>
        <section className="seccion-testimonios">
          <h2>Lo que nuestros usuarios dicen</h2>
          <div className="testimonios">
            <div className="testimonio">
              <p>"CitaMédica.co me facilitó agendar citas para mis hijos. Es rápido y sencillo."</p>
              <h3>- Laura Gómez, Madre y Tutora</h3>
            </div>
            <div className="testimonio">
              <p>"Ahora puedo gestionar mis citas y acceder a mi historial médico desde mi celular."</p>
              <h3>- Carlos Pérez, Paciente</h3>
            </div>
          </div>
        </section>
        <section className="seccion-seguridad">
          <h2>Tu seguridad es nuestra prioridad</h2>
          <p>
            En CitaMédica.co, implementamos los más altos estándares de seguridad para proteger tu información personal y médica.
          </p>
          <ImagenOptimizada
            src="./imagenes/seguridad.jpg"
            alt="Candado representando seguridad de datos"
            className="imagen-seguridad"
          />
        </section>
        <section className="seccion-llamada-accion">
          <h2>¿Listo para mejorar tu experiencia de salud?</h2>
          <p>No esperes más. Únete a miles de usuarios satisfechos.</p>
          {state.estaAutenticado ? (
            <Link to="/agendar" className="boton-cta-grande">
              <Boton>Agendar Cita Ahora</Boton>
            </Link>
          ) : (
            <Link to="/registro" className="boton-cta-grande">
              <Boton>Crear Cuenta Gratis</Boton>
             </Link>
            )}
        </section>
      </main>
      <footer className="pie-pagina">
        <p>&copy; 2024 CitaMédica.co. Todos los derechos reservados.</p>
      </footer>
    </div>
  );
};

export default PantallaInicio;
