import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../context/ContextoApp';
import { useTheme } from '../../context/ContextoTema';
import { useErrorHandler } from '../../utils/manejadorErrores';
import api from '../../services/api';
import { Tarjeta, TarjetaEncabezado, TarjetaContenido } from '../ui/tarjeta';
import Boton from '../ui/boton';
import './PanelAgente.css';

const PanelAgente = () => {
  const [citas, setCitas] = useState([]);
  const [estadisticas, setEstadisticas] = useState({ citasGestionadas: 0, llamadasRealizadas: 0, tareasPendientes: 0 });
  const navigate = useNavigate();
  const { state } = useAppContext();
  const { isDarkMode } = useTheme();
  const manejarError = useErrorHandler();

  useEffect(() => {
    const obtenerDatosPanelControl = async () => {
      try {
        const [citasRespuesta, estadisticasRespuesta] = await Promise.all([
          api.get('/agente/citas/'),
          api.get('/agente/estadisticas/')
        ]);
        setCitas(citasRespuesta.data);
        setEstadisticas(estadisticasRespuesta.data);
      } catch (error) {
        manejarError(error);
      }
    };

    obtenerDatosPanelControl();
  }, [manejarError]);

  const verDetalleCita = (citaId) => {
    navigate(`/cita/${citaId}`);
  };

//  Falta implementar
  const completarTarea = async (tareaId) => {
    try {
      await api.patch(`/agente/tareas/${tareaId}/completar/`);
      const estadisticasRespuesta = await api.get('/agente/estadisticas/');
      setEstadisticas(estadisticasRespuesta.data);
    } catch (error) {
      manejarError(error);
    }
  };

  return (
    <div className={`panel-agente ${isDarkMode ? 'oscuro' : ''}`}>
      <h1>Bienvenido, Agente {state.usuario?.nombre} {state.usuario?.apellido}</h1>
      
      <div className="contenedor-estadisticas">
        <Tarjeta>
          <TarjetaEncabezado>Citas Gestionadas</TarjetaEncabezado>
          <TarjetaContenido>{estadisticas.citasGestionadas}</TarjetaContenido>
        </Tarjeta>
        <Tarjeta>
          <TarjetaEncabezado>Llamadas Realizadas</TarjetaEncabezado>
          <TarjetaContenido>{estadisticas.llamadasRealizadas}</TarjetaContenido>
        </Tarjeta>
        <Tarjeta>
          <TarjetaEncabezado>Tareas Pendientes</TarjetaEncabezado>
          <TarjetaContenido>{estadisticas.tareasPendientes}</TarjetaContenido>
        </Tarjeta>
      </div>

      <h2>Citas Próximas</h2>
      <div className="lista-citas">
        {citas.map((cita) => (
          <Tarjeta key={cita.id} className="item-cita">
            <TarjetaEncabezado>
              Paciente: {cita.paciente.nombre} {cita.paciente.apellido}
            </TarjetaEncabezado>
            <TarjetaContenido>
              <p>Fecha y Hora: {new Date(cita.fecha_hora).toLocaleString()}</p>
              <p>Médico: Dr. {cita.medico.nombre} {cita.medico.apellido}</p>
              <p>Estado: {cita.estado}</p>
              <Boton onClick={() => verDetalleCita(cita.id)}>
                Ver Detalles
              </Boton>
            </TarjetaContenido>
          </Tarjeta>
        ))}
      </div>
      <Boton onClick={() => navigate('/agente/tareas/')} className="boton-ver-tareas">
        Ver Tareas Pendientes
      </Boton>
    </div>
  );
};

export default PanelAgente;
