import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useAppContext } from '../context/ContextoApp';
import { useTheme } from '../context/ContextoTema';
import { useErrorHandler } from '../utils/manejadorErrores';
import { iniciarSesion } from '../services/auth';
import { sanitizarEntrada } from '../utils/sanitizador';
import BarraNavegacion from '../components/BarraNavegacion';
import Boton from '../components/ui/boton';
import './PantallaInicioSesion.css';

const EsquemaInicioSesion = Yup.object().shape({
  identificador: Yup.string()
    .required('Este campo es requerido'),
  contraseña: Yup.string()
    .required('La contraseña es requerida')
    .min(8, 'La contraseña debe tener al menos 8 caracteres'),
});

const PantallaInicioSesion = () => {
  const navigate = useNavigate();
  const { dispatch } = useAppContext();
  const { isDarkMode } = useTheme();
  const manejarError = useErrorHandler();
  const [metodoInicioSesion, setMetodoInicioSesion] = useState('documento');

  const manejarEnvio = async (valores, { setSubmitting, setErrors }) => {
    try {
      const valoresSanitizados = {
        identificador: sanitizarEntrada(valores.identificador),
        contraseña: valores.contraseña,
      };
      const usuario = await iniciarSesion(valoresSanitizados);
      dispatch({ type: 'INICIAR_SESION', payload: usuario });
      dispatch({ type: 'AGREGAR_NOTIFICACION', payload: 'Inicio de sesión exitoso' });
      navigate('/panel');
    } catch (error) {
      manejarError(error);
      setErrors({ general: 'Credenciales inválidas' });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className={`pantalla-inicio-sesion ${isDarkMode ? 'oscuro' : ''}`}>
      <BarraNavegacion />
      <div className="contenedor-inicio-sesion">
        <div className="contenedor-formulario-inicio-sesion">
          <h1>Iniciar Sesión</h1>
          <div className="pestañas-inicio-sesion">
            <button
              className={`pestaña ${metodoInicioSesion === 'documento' ? 'activo' : ''}`}
              onClick={() => setMetodoInicioSesion('documento')}
            >
              Por Documento
            </button>
            <button
              className={`pestaña ${metodoInicioSesion === 'email' ? 'activo' : ''}`}
              onClick={() => setMetodoInicioSesion('email')}
            >
              Por Email
            </button>
          </div>
          <Formik
            initialValues={{ identificador: '', contraseña: '' }}
            validationSchema={EsquemaInicioSesion}
            onSubmit={manejarEnvio}
          >
            {({ isSubmitting, errors }) => (
              <Form className="formulario-inicio-sesion">
                <div className="grupo-formulario">
                  <label htmlFor="identificador">
                    {metodoInicioSesion === 'documento' ? 'Número de Documento:' : 'Correo Electrónico:'}
                  </label>
                  <Field
                    type={metodoInicioSesion === 'documento' ? 'text' : 'email'}
                    id="identificador"
                    name="identificador"
                    placeholder={metodoInicioSesion === 'documento' ? 'Ingrese su número de documento' : 'Ingrese su correo electrónico'}
                    aria-required="true"
                    aria-invalid={errors.identificador ? "true" : "false"}
                  />
                  <ErrorMessage name="identificador" component="div" className="mensaje-error" />
                </div>
                <div className="grupo-formulario">
                  <label htmlFor="contraseña">Contraseña:</label>
                  <Field
                    type="password"
                    id="contraseña"
                    name="contraseña"
                    placeholder="Ingrese su contraseña"
                    aria-required="true"
                    aria-invalid={errors.contraseña ? "true" : "false"}
                  />
                  <ErrorMessage name="contraseña" component="div" className="mensaje-error" />
                </div>
                {errors.general && <div className="mensaje-error">{errors.general}</div>}
                <Boton type="submit" disabled={isSubmitting} className="boton-inicio-sesion">
                  {isSubmitting ? 'Iniciando sesión...' : 'Iniciar Sesión'}
                </Boton>
              </Form>
            )}
          </Formik>
          <p className="enlace-registro">
            ¿No tienes una cuenta? <Link to="/registro">Regístrate aquí</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PantallaInicioSesion;
