import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useAppContext } from '../context/ContextoApp';
import { useTheme } from '../context/ContextoTema';
import { useErrorHandler } from '../utils/manejadorErrores';
import api from '../services/api';
import Boton from './ui/boton';
import './RegistroMenorTutor.css';

const RegistroSchema = Yup.object().shape({
  tipoUsuario: Yup.string().required('Seleccione el tipo de usuario'),
  nombreUsuario: Yup.string().required('El nombre de usuario es requerido'),
  email: Yup.string().email('Correo electrónico inválido').required('El correo electrónico es requerido'),
  contraseña: Yup.string().min(8, 'La contraseña debe tener al menos 8 caracteres').required('La contraseña es requerida'),
  nombre: Yup.string().required('El nombre es requerido'),
  apellido: Yup.string().required('El apellido es requerido'),
  telefono: Yup.string().required('El número de teléfono es requerido'),
  relacion: Yup.string().when('tipoUsuario', {
    is: 'tutor',
    then: Yup.string().required('La relación con el menor es requerida')
  }),
  fechaNacimiento: Yup.date().when('tipoUsuario', {
    is: 'menor',
    then: Yup.date().required('La fecha de nacimiento es requerida').max(new Date(), 'La fecha de nacimiento no puede ser futura')
  }),
  idTutor: Yup.string().when('tipoUsuario', {
    is: 'menor',
    then: Yup.string().required('El ID del tutor es requerido')
  })
});

const RegistroMenorTutor = () => {
  const [registroExitoso, setRegistroExitoso] = useState(false);
  const [nuevoMenorId, setNuevoMenorId] = useState(null);
  const navigate = useNavigate();
  const { dispatch } = useAppContext();
  const { isDarkMode } = useTheme();
  const manejarError = useErrorHandler();
const handleSubmit = async (valores, { setSubmitting }) => {
  // ... código existente ...
};

  return (
    <div className={`registro-menor-tutor ${isDarkMode ? 'dark' : ''}`}>
      <h2>Registro de {registroExitoso ? 'Menor' : 'Tutor/Menor'}</h2>
      <Formik
        initialValues={{
          tipoUsuario: '',
          nombreUsuario: '',
          email: '',
          contraseña: '',
          nombre: '',
          apellido: '',
          telefono: '',
          relacion: '',
          fechaNacimiento: '',
          idTutor: ''
        }}
        validationSchema={RegistroSchema}
        onSubmit={handleSubmit}
      >
        {({ values, isSubmitting }) => (
          <Form>
            <div className="form-group">
              <label htmlFor="tipoUsuario">Tipo de Usuario</label>
              <Field as="select" name="tipoUsuario">
                <option value="">Seleccione el tipo de usuario</option>
                <option value="tutor">Tutor</option>
                <option value="menor">Menor</option>
              </Field>
              <ErrorMessage name="tipoUsuario" component="div" className="error-message" />
            </div>

            <div className="form-group">
              <label htmlFor="nombreUsuario">Nombre de Usuario</label>
              <Field type="text" name="nombreUsuario" />
              <ErrorMessage name="nombreUsuario" component="div" className="error-message" />
            </div>

            <div className="form-group">
              <label htmlFor="email">Correo Electrónico</label>
              <Field type="email" name="email" />
              <ErrorMessage name="email" component="div" className="error-message" />
            </div>

            <div className="form-group">
              <label htmlFor="contraseña">Contraseña</label>
              <Field type="password" name="contraseña" />
              <ErrorMessage name="contraseña" component="div" className="error-message" />
            </div>

            <div className="form-group">
              <label htmlFor="nombre">Nombre</label>
              <Field type="text" name="nombre" />
              <ErrorMessage name="nombre" component="div" className="error-message" />
            </div>

            <div className="form-group">
              <label htmlFor="apellido">Apellido</label>
              <Field type="text" name="apellido" />
              <ErrorMessage name="apellido" component="div" className="error-message" />
            </div>

            <div className="form-group">
              <label htmlFor="telefono">Número de Teléfono</label>
              <Field type="tel" name="telefono" />
              <ErrorMessage name="telefono" component="div" className="error-message" />
            </div>

            {values.tipoUsuario === 'tutor' && (
              <div className="form-group">
                <label htmlFor="relacion">Relación con el Menor</label>
                <Field type="text" name="relacion" />
                <ErrorMessage name="relacion" component="div" className="error-message" />
              </div>
            )}

            {values.tipoUsuario === 'menor' && (
              <>
                <div className="form-group">
                  <label htmlFor="fechaNacimiento">Fecha de Nacimiento</label>
                  <Field type="date" name="fechaNacimiento" />
                  <ErrorMessage name="fechaNacimiento" component="div" className="error-message" />
                </div>
                <div className="form-group">
                  <label htmlFor="idTutor">ID del Tutor</label>
                  <Field type="text" name="idTutor" />
                  <ErrorMessage name="idTutor" component="div" className="error-message" />
                </div>
              </>
            )}

            <Boton type="submit" disabled={isSubmitting}>
              {isSubmitting ? 'Registrando...' : 'Registrarse'}
            </Boton>
          </Form>
        )}
      </Formik>

      {registroExitoso && (
        <div className="registro-exitoso">
          <p>Registro exitoso del menor. ID: {nuevoMenorId}</p>
          <Boton onClick={() => navigate('/dashboard')}>Ir al Dashboard</Boton>
        </div>
      )}
    </div>
  );
};

export default RegistroMenorTutor;
