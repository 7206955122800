import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { format, startOfMonth, endOfMonth, eachDayOfInterval, isToday, isSameMonth } from 'date-fns';
import { es } from 'date-fns/locale';
import { useAppContext } from '../../context/ContextoApp';
import { useTheme } from '../../context/ContextoTema';
import api from '../../services/api';
import { Tarjeta, TarjetaEncabezado, TarjetaContenido } from '../ui/tarjeta';
import Boton from '../ui/boton';
import './CalendarioCitas.css';

const CalendarioCitas = () => {
  const [fechaActual, setFechaActual] = useState(new Date());
  const [fechaSeleccionada, setFechaSeleccionada] = useState(new Date());
  const [citas, setCitas] = useState([]);
  const [citaSeleccionada, setCitaSeleccionada] = useState(null);

  const { dispatch } = useAppContext();
  const { isDarkMode } = useTheme();
  const navigate = useNavigate();

  const obtenerCitas = useCallback(async (inicio, fin) => {
    try {
      const respuesta = await api.get('citas/', {
        params: {
          fecha_inicio: format(inicio, 'yyyy-MM-dd'),
          fecha_fin: format(fin, 'yyyy-MM-dd')
        }
      });
      setCitas(respuesta.data);
    } catch (error) {
      console.error('Error al cargar las citas:', error);
      dispatch({ type: 'AGREGAR_NOTIFICACION', payload: { tipo: 'error', mensaje: 'Error al cargar las citas' } });
    }
  }, [dispatch]);

  useEffect(() => {
    const inicio = startOfMonth(fechaActual);
    const fin = endOfMonth(fechaActual);
    obtenerCitas(inicio, fin);
  }, [fechaActual, obtenerCitas]);

  const diasEnMes = eachDayOfInterval({
    start: startOfMonth(fechaActual),
    end: endOfMonth(fechaActual)
  });

  const manejarClickFecha = (fecha) => {
    setFechaSeleccionada(fecha);
    setCitaSeleccionada(null);
  };

  const manejarClickCita = (cita) => {
    setCitaSeleccionada(cita);
  };

  const manejarMesAnterior = () => {
    setFechaActual(prevFecha => new Date(prevFecha.getFullYear(), prevFecha.getMonth() - 1, 1));
  };

  const manejarMesSiguiente = () => {
    setFechaActual(prevFecha => new Date(prevFecha.getFullYear(), prevFecha.getMonth() + 1, 1));
  };

  const manejarNuevaCita = () => {
    navigate('/agendar', { state: { fechaSeleccionada } });
  };

  const obtenerCitasPorFecha = (fecha) => {
    return citas.filter(cita => 
      format(new Date(cita.fecha_hora), 'yyyy-MM-dd') === format(fecha, 'yyyy-MM-dd')
    );
  };

  return (
    <div className={`calendario-citas ${isDarkMode ? 'oscuro' : ''}`}>
      <div className="encabezado-calendario">
        <Boton onClick={manejarMesAnterior}>&lt;</Boton>
        <h2>{format(fechaActual, 'MMMM yyyy', { locale: es })}</h2>
        <Boton onClick={manejarMesSiguiente}>&gt;</Boton>
      </div>
      <div className="cuadricula-calendario">
        {['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'].map(dia => (
          <div key={dia} className="encabezado-dia-calendario">{dia}</div>
        ))}
        {diasEnMes.map(fecha => (
          <div
            key={fecha.toString()}
            className={`dia-calendario ${
              isToday(fecha) ? 'hoy' : ''
            } ${isSameMonth(fecha, fechaActual) ? '' : 'otro-mes'} ${
              format(fecha, 'yyyy-MM-dd') === format(fechaSeleccionada, 'yyyy-MM-dd') ? 'seleccionado' : ''
            }`}
            onClick={() => manejarClickFecha(fecha)}
          >
            <span className="numero-dia">{format(fecha, 'd')}</span>
            {obtenerCitasPorFecha(fecha).map(cita => (
              <div
                key={cita.id}
                className="indicador-cita"
                onClick={(e) => {
                  e.stopPropagation();
                  manejarClickCita(cita);
                }}
              />
            ))}
          </div>
        ))}
      </div>
      <Tarjeta className="detalles-cita">
        <TarjetaEncabezado>
          {citaSeleccionada ? 'Detalles de la Cita' : 'Citas del Día'}
        </TarjetaEncabezado>
        <TarjetaContenido>
          {citaSeleccionada ? (
            <div>
              <p>Fecha: {format(new Date(citaSeleccionada.fecha_hora), 'dd/MM/yyyy HH:mm')}</p>
              <p>Paciente: {citaSeleccionada.paciente.usuario.nombre} {citaSeleccionada.paciente.usuario.apellido}</p>
              <p>Doctor: Dr. {citaSeleccionada.doctor.usuario.nombre} {citaSeleccionada.doctor.usuario.apellido}</p>
              <p>Especialidad: {citaSeleccionada.doctor.especialidad}</p>
              <Boton onClick={() => setCitaSeleccionada(null)}>Cerrar</Boton>
            </div>
          ) : (
            <div>
              <h3>{format(fechaSeleccionada, 'dd/MM/yyyy')}</h3>
              {obtenerCitasPorFecha(fechaSeleccionada).length > 0 ? (
                obtenerCitasPorFecha(fechaSeleccionada).map(cita => (
                  <div key={cita.id} className="item-cita">
                    <span>{format(new Date(cita.fecha_hora), 'HH:mm')}</span>
                    <span>{cita.paciente.usuario.nombre} {cita.paciente.usuario.apellido}</span>
                    <Boton onClick={() => manejarClickCita(cita)}>Ver</Boton>
                  </div>
                ))
              ) : (
                <p>No hay citas para este día.</p>
              )}
            </div>
          )}
        </TarjetaContenido>
      </Tarjeta>
      <Boton onClick={manejarNuevaCita} className="boton-nueva-cita">Nueva Cita</Boton>
    </div>
  );
};

export default CalendarioCitas;
