import DOMPurify from 'dompurify';

/**
 * Sanitiza una cadena de texto para prevenir ataques XSS
 * @param {string} entrada - La cadena de texto a sanitizar
 * @returns {string} - La cadena de texto sanitizada
 */
export const sanitizarEntrada = (entrada) => {
  if (typeof entrada !== 'string') {
    return '';
  }
  return DOMPurify.sanitize(entrada, { ALLOWED_TAGS: [], ALLOWED_ATTR: [] });
};

/**
 * Sanitiza un objeto, aplicando sanitización a todas las cadenas de texto
 * @param {Object} obj - El objeto a sanitizar
 * @returns {Object} - El objeto sanitizado
 */
export const sanitizarObjeto = (obj) => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  const objetoSanitizado = Array.isArray(obj) ? [] : {};

  for (const clave in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, clave)) {
      const valor = obj[clave];
      if (typeof valor === 'string') {
        objetoSanitizado[clave] = sanitizarEntrada(valor);
      } else if (typeof valor === 'object' && valor !== null) {
        objetoSanitizado[clave] = sanitizarObjeto(valor);
      } else {
        objetoSanitizado[clave] = valor;
      }
    }
  }

  return objetoSanitizado;
};

/**
 * Sanitiza los datos de un formulario
 * @param {Object} datosFormulario - Los datos del formulario a sanitizar
 * @returns {Object} - Los datos del formulario sanitizados
 */
export const sanitizarDatosFormulario = (datosFormulario) => {
  return sanitizarObjeto(datosFormulario);
};

/**
 * Sanitiza una URL
 * @param {string} url - La URL a sanitizar
 * @returns {string} - La URL sanitizada
 */
export const sanitizarUrl = (url) => {
  if (typeof url !== 'string') {
    return '';
  }
  try {
    const urlAnalizada = new URL(url);
    return urlAnalizada.toString();
  } catch (error) {
    console.error('URL inválida:', url);
    return '';
  }
};

/**
 * Sanitiza HTML permitiendo solo ciertas etiquetas y atributos
 * @param {string} html - El HTML a sanitizar
 * @returns {string} - El HTML sanitizado
 */
export const sanitizarHtml = (html) => {
  return DOMPurify.sanitize(html, {
    ALLOWED_TAGS: ['b', 'i', 'em', 'strong', 'a', 'p', 'br'],
    ALLOWED_ATTR: ['href', 'target']
  });
};

const utilidadesSanitizacion = {
  sanitizarEntrada,
  sanitizarObjeto,
  sanitizarDatosFormulario,
  sanitizarUrl,
  sanitizarHtml
};

export default utilidadesSanitizacion;
