import React from 'react';
import { Routes, Route } from 'react-router-dom';
import PantallaInicio from './screens/PantallaInicio';
import PantallaInicioSesion from './screens/PantallaInicioSesion';
import PantallaRegistro from './screens/PantallaRegistro';
import PantallaPanel from './screens/PantallaPanel';
import PantallaAgendarCita from './screens/PantallaAgendarCita';
import PanelPaciente from './components/dashboard/PanelPaciente';
import PanelDoctor from './components/dashboard/PanelDoctor';
import PanelTutor from './components/dashboard/PanelTutor';
import PanelAgente from './components/dashboard/PanelAgente';
import CalendarioCitas from './components/calendar/CalendarioCitas';
import FormularioAutorizacion from './components/authorization/FormularioAutorizacion';
import RegistroMenorTutor from './components/RegistroMenorTutor';
import TerminosServicio from './screens/TerminosServicio';
import PantallaConfirmacionCorreo from './screens/PantallaConfirmacionCorreo';

const ContenidoApp = () => {
  return (
    <Routes>
      <Route path="/" element={<PantallaInicio />} />
      <Route path="/inicio-sesion" element={<PantallaInicioSesion />} />
      <Route path="/registro" element={<PantallaRegistro />} />
      <Route path="/confirmacion-correo" element={<PantallaConfirmacionCorreo />} />
      <Route path="/panel" element={<PantallaPanel />} />
      <Route path="/agendar" element={<PantallaAgendarCita />} />
      <Route path="/panel-paciente" element={<PanelPaciente />} />
      <Route path="/panel-doctor" element={<PanelDoctor />} />
      <Route path="/panel-tutor" element={<PanelTutor />} />
      <Route path="/panel-agente" element={<PanelAgente />} />
      <Route path="/calendario" element={<CalendarioCitas />} />
      <Route path="/autorizar" element={<FormularioAutorizacion />} />
      <Route path="/registro-menor-tutor" element={<RegistroMenorTutor />} />
      <Route path="/terminos-servicio" element={<TerminosServicio />} />
    </Routes>
  );
};

export default ContenidoApp;
