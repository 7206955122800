import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './ImagenOptimizada.css';

const ImagenOptimizada = ({ src, alt, className, width, height, carga = true, placeholder = '/imagenes/placeholder.jpg' }) => {
  const [fuenteImagen, setFuenteImagen] = useState(carga ? placeholder : src);
  const [referenciaImagen, setReferenciaImagen] = useState();

  useEffect(() => {
    let observer;
    let cancelado = false;

    if (carga && referenciaImagen && fuenteImagen === placeholder) {
      if (IntersectionObserver) {
        observer = new IntersectionObserver(
          entradas => {
            entradas.forEach(entrada => {
              if (
                !cancelado &&
                (entrada.intersectionRatio > 0 || entrada.isIntersecting)
              ) {
                setFuenteImagen(src);
                observer.unobserve(referenciaImagen);
              }
            });
          },
          {
            threshold: 0.01,
            rootMargin: '75%',
          }
        );
        observer.observe(referenciaImagen);
      } else {
        // Fallback para navegadores que no soportan IntersectionObserver
        setFuenteImagen(src);
      }
    }

    return () => {
      cancelado = true;
      if (observer && observer.unobserve) {
        observer.unobserve(referenciaImagen);
      }
    };
  }, [carga, src, fuenteImagen, referenciaImagen, placeholder]);

  return (
    <img
      className={`imagen-optimizada ${className || ''}`}
      ref={setReferenciaImagen}
      src={fuenteImagen}
      alt={alt}
      width={width}
      height={height}
      loading={carga ? "lazy" : "eager"}
    />
  );
};

ImagenOptimizada.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  carga: PropTypes.bool,
  placeholder: PropTypes.string,
};

export default ImagenOptimizada;
