import React from 'react';
import { useTheme } from '../context/ContextoTema';
import './CambiarTema.css';

const CambiarTema = () => {
  const { isDarkMode, toggleTheme } = useTheme();

  return (
    <button 
      className={`cambiar-tema ${isDarkMode ? 'oscuro' : 'claro'}`} 
      onClick={toggleTheme}
      aria-label={isDarkMode ? "Cambiar a modo claro" : "Cambiar a modo oscuro"}
    >
      <div className="pista-cambio">
        <div className="indicador-cambio">
          <span className="icono-cambio">
            {isDarkMode ? '🌙' : '☀️'}
          </span>
        </div>
      </div>
      <span className="etiqueta-cambio">
        {isDarkMode ? 'Modo Oscuro' : 'Modo Claro'}
      </span>
    </button>
  );
};

export default CambiarTema;
