import api from './api';

export const registro = async (datosUsuario) => {
  try {
    const respuesta = await api.post('/registro/', datosUsuario);
    console.log('Respuesta de registro:', respuesta);
    // Aquí se asume que el backend envía el correo de confirmación
    return respuesta.data;
  } catch (error) {
    console.error('Error de registro:', error.response ? error.response.data : error);
    throw error;
  }
};

export const iniciarSesion = async (credenciales) => {
  try {
    const respuesta = await api.post('/inicio-sesion/', credenciales);
    if (respuesta.data.access) {
      localStorage.setItem('token_acceso', respuesta.data.access);
      localStorage.setItem('token_renovacion', respuesta.data.refresh);
    }
    return respuesta.data;
  } catch (error) {
    console.error('Error de inicio de sesión:', error.response ? error.response.data : error);
    throw error;
  }
};

export const cerrarSesion = async () => {
  try {
    localStorage.removeItem('token_acceso');
    localStorage.removeItem('token_renovacion');
  } catch (error) {
    console.error('Error de cierre de sesión:', error);
    throw error;
  }
};
