import React, { useState } from 'react';
import { useTheme } from '../../context/ContextoTema';
import './seleccion.css';

export const Seleccion = React.forwardRef(({ children, onValueChange, ...props }, ref) => {
  const [estaAbierto, setEstaAbierto] = useState(false);
  const [valorSeleccionado, setValorSeleccionado] = useState(null);  // Declaración de valor seleccionado
  const { isDarkMode } = useTheme();

  const handleSelect = (valor) => {
    setValorSeleccionado(valor);  // Asigna el valor seleccionado
    onValueChange(valor);  // Llama al callback con el valor seleccionado
    setEstaAbierto(false);  // Cierra el menú
  };

  return (
    <div className={`contenedor-seleccion ${isDarkMode ? 'oscuro' : ''}`} ref={ref} {...props}>
      {React.Children.map(children, (child) => {
        if (child.type === SeleccionTrigger) {
          return React.cloneElement(child, { onClick: () => setEstaAbierto(!estaAbierto) });
        }
        if (child.type === SeleccionContent && estaAbierto) {
          return React.cloneElement(child, { onSelect: handleSelect });
        }
        return child;
      })}
    </div>
  );
});

export const SeleccionTrigger = ({ children, onClick }) => (
  <button type="button" className="disparador-seleccion" onClick={onClick}>
    {children}
  </button>
);

export const SeleccionValue = ({ placeholder }) => (
  <span className="valor-seleccion">{placeholder || 'Seleccionar una opción'}</span>
);

export const SeleccionContent = ({ children, onSelect }) => (
  <div className="contenido-seleccion">
    {React.Children.map(children, (child) =>
      React.cloneElement(child, { onSelect })
    )}
  </div>
);

export const SeleccionItem = ({ children, value, onSelect }) => (
  <div className="opcion-seleccion" onClick={() => onSelect(value)}>
    {children}
  </div>
);

Seleccion.displayName = 'Seleccion';
