import React from 'react';
import { useTheme } from '../../context/ContextoTema';
import './tarjeta.css';

export const Tarjeta = ({ children, className = '', onClick }) => {
  const { isDarkMode } = useTheme();
  return (
    <div 
      className={`tarjeta ${isDarkMode ? 'oscuro' : ''} ${className}`}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export const TarjetaEncabezado = ({ children, className = '' }) => (
  <div className={`tarjeta-encabezado ${className}`}>
    {children}
  </div>
);

export const TarjetaTitulo = ({ children, className = '' }) => (
  <h3 className={`tarjeta-titulo ${className}`}>
    {children}
  </h3>
);

export const TarjetaContenido = ({ children, className = '' }) => (
  <div className={`tarjeta-contenido ${className}`}>
    {children}
  </div>
);

export const TarjetaPie = ({ children, className = '' }) => (
  <div className={`tarjeta-pie ${className}`}>
    {children}
  </div>
);

const ComponentesTarjeta = {
  Tarjeta,
  TarjetaEncabezado,
  TarjetaTitulo,
  TarjetaContenido,
  TarjetaPie
};

export default ComponentesTarjeta;
