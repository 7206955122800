import React from 'react';
import { useTheme } from '../context/ContextoTema';
import './Cargando.css';

const Cargando = ({ tamaño = 'medio', pantallaCompleta = false }) => {
  const { isDarkMode } = useTheme();

  const tamañoSpinner = {
    pequeño: 'w-6 h-6',
    medio: 'w-12 h-12',
    grande: 'w-16 h-16'
  };

  const claseContenedor = pantallaCompleta
    ? 'fixed inset-0 flex items-center justify-center bg-white bg-opacity-75 z-50'
    : 'flex items-center justify-center';

  return (
    <div className={`${claseContenedor} ${isDarkMode ? 'oscuro' : ''}`}>
      <div className={`spinner ${tamañoSpinner[tamaño]} ${isDarkMode ? 'oscuro' : ''}`}>
        <div className="doble-rebote1"></div>
        <div className="doble-rebote2"></div>
      </div>
    </div>
  );
};

export default Cargando;
