import React, { createContext, useContext, useReducer, useEffect } from 'react';
import { asyncActionMiddleware } from './middleware';

// Definir el estado inicial
const estadoInicial = {
  usuario: null,
  estaAutenticado: false,
  notificaciones: [],
  cargando: false,
  error: null
};

// Definir las acciones
const Acciones = {
  ESTABLECER_USUARIO: 'ESTABLECER_USUARIO',
  CERRAR_SESION: 'CERRAR_SESION',
  AGREGAR_NOTIFICACION: 'AGREGAR_NOTIFICACION',
  ELIMINAR_NOTIFICACION: 'ELIMINAR_NOTIFICACION',
  ESTABLECER_CARGANDO: 'ESTABLECER_CARGANDO',
  ESTABLECER_ERROR: 'ESTABLECER_ERROR',
  LIMPIAR_ERROR: 'LIMPIAR_ERROR'
};

// Definir el reducer
const reductorApp = (state, accion) => {
  switch (accion.type) {
    case Acciones.ESTABLECER_USUARIO:
      return { ...state, usuario: accion.payload, estaAutenticado: true };
    case Acciones.CERRAR_SESION:
      return { ...state, usuario: null, estaAutenticado: false };
    case Acciones.AGREGAR_NOTIFICACION:
      return { ...state, notificaciones: [...state.notificaciones, accion.payload] };
    case Acciones.ELIMINAR_NOTIFICACION:
      return { ...state, notificaciones: state.notificaciones.filter(n => n.id !== accion.payload) };
    case Acciones.ESTABLECER_CARGANDO:
      return { ...state, cargando: accion.payload };
    case Acciones.ESTABLECER_ERROR:
      return { ...state, error: accion.payload };
    case Acciones.LIMPIAR_ERROR:
      return { ...state, error: null };
    default:
      return state;
  }
};

// Crear el contexto
const ContextoApp = createContext();

// Crear un proveedor de contexto
export const ProveedorApp = ({ children }) => {
  const [state, dispatchBase] = useReducer(reductorApp, estadoInicial);
  const dispatch = asyncActionMiddleware(dispatchBase);

  // Cargar el usuario desde localStorage al iniciar la aplicación
  useEffect(() => {
    const usuario = JSON.parse(localStorage.getItem('usuario'));
    if (usuario) {
      dispatch({ type: Acciones.ESTABLECER_USUARIO, payload: usuario });
    }
  }, [dispatch]);

  // Actualizar localStorage cuando cambia el usuario
  useEffect(() => {
    if (state.usuario) {
      localStorage.setItem('usuario', JSON.stringify(state.usuario));
    } else {
      localStorage.removeItem('usuario');
    }
  }, [state.usuario]);

  return (
    <ContextoApp.Provider value={{ state, dispatch }}>
      {children}
    </ContextoApp.Provider>
  );
};

// Crear un hook personalizado para usar el contexto
export const useAppContext = () => {
  const context = useContext(ContextoApp);
  if (context === undefined) {
    throw new Error('useAppContext debe ser usado dentro de un ProveedorApp');
  }
  return context;
};

// Exportar las acciones
export { Acciones };
