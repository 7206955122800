import React from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '../context/ContextoTema';
import BarraNavegacion from '../components/BarraNavegacion';
import './PantallaConfirmacionCorreo.css';

const PantallaConfirmacionCorreo = () => {
  const { isDarkMode } = useTheme();

  return (
    <div className={`pantalla-confirmacion-correo ${isDarkMode ? 'oscuro' : ''}`}>
      <BarraNavegacion />
      <div className="contenedor-confirmacion-correo">
        <h1>Confirma tu correo electrónico</h1>
        <p>Hemos enviado un correo de confirmación a tu dirección de email.</p>
        <p>Por favor, revisa tu bandeja de entrada y haz clic en el enlace de verificación.</p>
        <p>Si no encuentras el correo, revisa tu carpeta de spam o correo no deseado.</p>
        <p>Una vez que hayas confirmado tu correo, podrás <Link to="/inicio-sesion">iniciar sesión</Link>.</p>
        <div className="acciones-confirmacion">
          <button className="boton-reenviar">Reenviar correo de confirmación</button>
          <Link to="/inicio-sesion" className="enlace-inicio-sesion">Volver a inicio de sesión</Link>
        </div>
      </div>
    </div>
  );
};

export default PantallaConfirmacionCorreo;
