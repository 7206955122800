import React from 'react';
import * as Sentry from "@sentry/react";
import { ProveedorTema, useTheme } from '../context/ContextoTema';
import Boton from './ui/boton';
import './LimiteErrores.css';

class LímiteErrores extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hayError: false, error: null, infoError: null };
  }

  static getDerivedStateFromError(error) {
    return { hayError: true };
  }

  componentDidCatch(error, infoError) {
    console.error("Error no capturado:", error, infoError);
    this.setState({ error, infoError });
    Sentry.captureException(error);
  }

  render() {
    if (this.state.hayError) {
      return (
        <ProveedorTema>
          <PantallaError error={this.state.error} infoError={this.state.infoError} />
        </ProveedorTema>
      );
    }
    return this.props.children;
  }
}

const PantallaError = ({ error, infoError }) => {
  const { isDarkMode } = useTheme();

  return (
    <div className={`limite-errores ${isDarkMode ? 'oscuro' : ''}`}>
      <h1>Algo salió mal</h1>
      <p>Lo sentimos, ha ocurrido un error inesperado.</p>
      <p>Nuestro equipo técnico ha sido notificado y estamos trabajando para solucionarlo.</p>
      <details>
        <summary>Detalles del error</summary>
        <pre>{error && error.toString()}</pre>
        <pre>{infoError.componentStack}</pre>
      </details>
      <div className="acciones-error">
        <Boton onClick={() => window.location.reload()}>
          Recargar la página
        </Boton>
        <Boton onClick={() => window.location.href = '/'}>
          Volver a la página de inicio
        </Boton>
      </div>
    </div>
  );
};

export default LímiteErrores;
