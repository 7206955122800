import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../context/ContextoApp';
import { useTheme } from '../../context/ContextoTema';
import { useErrorHandler } from '../../utils/manejadorErrores';
import api from '../../services/api';
import { Tarjeta, TarjetaEncabezado, TarjetaContenido } from '../ui/tarjeta';
import Boton from '../ui/boton';
import './PanelTutor.css';

const PanelTutor = () => {
  const [menores, setMenores] = useState([]);
  const [estadisticas, setEstadisticas] = useState({ totalMenores: 0, citasPendientes: 0 });
  const navigate = useNavigate();
  const { state } = useAppContext();
  const { isDarkMode } = useTheme();
  const manejarError = useErrorHandler();

  useEffect(() => {
    const obtenerDatosPanelControl = async () => {
      try {
        const [menoresRespuesta, estadisticasRespuesta] = await Promise.all([
          api.get('tutor/menores/'),
          api.get('tutor/estadisticas/')
        ]);
        setMenores(menoresRespuesta.data);
        setEstadisticas(estadisticasRespuesta.data);
      } catch (error) {
        manejarError(error);
      }
    };

    obtenerDatosPanelControl();
  }, [manejarError]);

  const verPerfilMenor = (menorId) => {
    navigate(`/menor/${menorId}`);
  };

  const agendarCita = (menorId) => {
    navigate(`/agendar-cita/${menorId}`);
  };

  return (
    <div className={`panel-tutor ${isDarkMode ? 'oscuro' : ''}`}>
      <h1>Bienvenido, {state.usuario?.nombre} {state.usuario?.apellido}</h1>
      
      <div className="contenedor-estadisticas">
        <Tarjeta>
          <TarjetaEncabezado>Total de Menores</TarjetaEncabezado>
          <TarjetaContenido>{estadisticas.totalMenores}</TarjetaContenido>
        </Tarjeta>
        <Tarjeta>
          <TarjetaEncabezado>Citas Pendientes</TarjetaEncabezado>
          <TarjetaContenido>{estadisticas.citasPendientes}</TarjetaContenido>
        </Tarjeta>
      </div>

      <h2>Menores a su Cargo</h2>
      <div className="lista-menores">
        {menores.map((menor) => (
          <Tarjeta key={menor.id} className="item-menor">
            <TarjetaEncabezado>
              {menor.nombre} {menor.apellido}
            </TarjetaEncabezado>
            <TarjetaContenido>
              <p>Edad: {menor.edad} años</p>
              <p>Próxima cita: {menor.proxima_cita ? new Date(menor.proxima_cita).toLocaleString() : 'No programada'}</p>
              <div className="grupo-botones">
                <Boton onClick={() => verPerfilMenor(menor.id)}>
                  Ver Perfil
                </Boton>
                <Boton onClick={() => agendarCita(menor.id)}>
                  Agendar Cita
                </Boton>
              </div>
            </TarjetaContenido>
          </Tarjeta>
        ))}
      </div>
      <Boton onClick={() => navigate('/registro-menor')} className="boton-registrar">
        Registrar Nuevo Menor
      </Boton>
    </div>
  );
};

export default PanelTutor;
