import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useAppContext } from '../context/ContextoApp';
import { useTheme } from '../context/ContextoTema';
import { useErrorHandler } from '../utils/manejadorErrores';
import { registro } from '../services/auth';
import { sanitizarEntrada } from '../utils/sanitizador';
import BarraNavegacion from '../components/BarraNavegacion';
import Boton from '../components/ui/boton';
// import { Seleccion, OpcionSeleccion } from '../components/ui/seleccion';
// import Calendario from '../components/ui/calendario';
// import { validarEdad, obtenerGrupoEdad } from '../utils/validacionEdad';
import './PantallaRegistro.css';

const EsquemaRegistro = Yup.object().shape({
  email: Yup.string()
    .email('Correo electrónico inválido')
    .required('El correo electrónico es requerido'),
  contraseña: Yup.string()
    .required('La contraseña es requerida')
    .min(8, 'La contraseña debe tener al menos 8 caracteres')
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/, 'La contraseña debe contener al menos una letra mayúscula, una minúscula y un número'),
  confirmarContraseña: Yup.string()
    .oneOf([Yup.ref('contraseña'), null], 'Las contraseñas deben coincidir')
    .required('Confirma tu contraseña'),
  nombre: Yup.string().required('El nombre es requerido'),
  apellido: Yup.string().required('El apellido es requerido'),
  telefono: Yup.string().required('El número de teléfono es requerido'),
  whatsapp: Yup.string().required('El número de WhatsApp es requerido'),
  tipoDocumento: Yup.string().required('El tipo de documento es requerido'),
  numeroDocumento: Yup.string().required('El número de documento es requerido'),
  tipoUsuario: Yup.array().min(1, 'Debe seleccionar al menos una opción').of(Yup.string().oneOf(['paciente-adulto', 'medico'])),
  especialidad: Yup.string().when('tipoUsuario', {
    is: (tipoUsuario) => tipoUsuario.includes('medico'),
    then: Yup.string().required('La especialidad es requerida para médicos'),
  }),
  numeroLicencia: Yup.string().when('tipoUsuario', {
    is: (tipoUsuario) => tipoUsuario.includes('medico'),
    then: Yup.string().required('El número de licencia es requerido para médicos'),
  }),
});

const PantallaRegistro = () => {
  const [errorServidor, setErrorServidor] = useState('');
  const navigate = useNavigate();
  const { dispatch } = useAppContext();
  const { isDarkMode } = useTheme();
  const manejarError = useErrorHandler();

  const manejarEnvio = async (valores, { setSubmitting, setErrors }) => {
    try {
      const valoresSanitizados = {
        ...valores,
        email: sanitizarEntrada(valores.email),
        nombre: sanitizarEntrada(valores.nombre),
        apellido: sanitizarEntrada(valores.apellido),
        telefono: sanitizarEntrada(valores.telefono),
        whatsapp: sanitizarEntrada(valores.whatsapp),
        numeroDocumento: sanitizarEntrada(valores.numeroDocumento),
        tipo_usuario: valores.tipoUsuario,
      };

      await registro(valoresSanitizados);
      dispatch({ type: 'AGREGAR_NOTIFICACION', payload: 'Registro completado con éxito. Por favor, verifica tu correo electrónico.' });
      navigate('/confirmacion-email');
    } catch (error) {
      const mensajeError = manejarError(error);
      setErrorServidor(mensajeError);
      setErrors({ general: mensajeError });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className={`pantalla-registro ${isDarkMode ? 'oscuro' : ''}`}>
      <BarraNavegacion />
      <div className="contenedor-registro">
        <div className="contenedor-formulario-registro">
          <h1>Registrarse</h1>
          <Formik
            initialValues={{
              email: '',
              contraseña: '',
              confirmarContraseña: '',
              nombre: '',
              apellido: '',
              telefono: '',
              whatsapp: '',
              tipoDocumento: '',
              numeroDocumento: '',
              tipoUsuario: [],
              especialidad: '',
              numeroLicencia: '',
            }}
            validationSchema={EsquemaRegistro}
            onSubmit={manejarEnvio}
          >
            {({ isSubmitting, errors, touched, values, setFieldValue }) => (
              <Form className="formulario-registro">
                {errorServidor && <div className="mensaje-error">{errorServidor}</div>}

                <div className="tipo-registro">
                  <label>
                    <Field
                      type="checkbox"
                      name="tipoUsuario"
                      value="paciente-adulto"
                      checked={values.tipoUsuario.includes('paciente-adulto')}
                      onChange={(e) => {
                        const nuevoTipoUsuario = e.target.checked
                          ? [...values.tipoUsuario, 'paciente-adulto']
                          : values.tipoUsuario.filter(type => type !== 'paciente-adulto');
                        setFieldValue('tipoUsuario', nuevoTipoUsuario);
                      }}
                    />
                    Registrarse como paciente
                  </label>
                  <label>
                    <Field
                      type="checkbox"
                      name="tipoUsuario"
                      value="medico"
                      checked={values.tipoUsuario.includes('medico')}
                      onChange={(e) => {
                        const nuevoTipoUsuario = e.target.checked
                          ? [...values.tipoUsuario, 'medico']
                          : values.tipoUsuario.filter(type => type !== 'medico');
                        setFieldValue('tipoUsuario', nuevoTipoUsuario);
                      }}
                    />
                    Registrarse como médico
                  </label>
                </div>
                <ErrorMessage name="tipoUsuario" component="div" className="mensaje-error" />

                {values.tipoUsuario.includes('medico') && (
                  <div className="campos-medico">
                    <Field type="text" name="especialidad" placeholder="Especialidad" />
                    <ErrorMessage name="especialidad" component="div" className="mensaje-error" />
                    <Field type="text" name="numeroLicencia" placeholder="Número de Licencia" />
                    <ErrorMessage name="numeroLicencia" component="div" className="mensaje-error" />
                  </div>
                )}

                <div className="columnas-formulario">
                  <div className="columna-formulario">
                    <Field as="select" name="tipoDocumento">
                      <option value="">Tipo de Documento</option>
                      <option value="CC">Cédula de ciudadanía</option>
                      <option value="CE">Cédula de extranjería</option>
                      <option value="TI">Tarjeta de Identidad</option>
                    </Field>
                    <ErrorMessage name="tipoDocumento" component="div" className="mensaje-error" />
                    <Field type="text" name="numeroDocumento" placeholder="Número de Documento" />
                    <ErrorMessage name="numeroDocumento" component="div" className="mensaje-error" />
                    <Field type="email" name="email" placeholder="Correo Electrónico" />
                    <ErrorMessage name="email" component="div" className="mensaje-error" />
                    <Field type="password" name="contraseña" placeholder="Contraseña" />
                    <ErrorMessage name="contraseña" component="div" className="mensaje-error" />
                    <Field type="password" name="confirmarContraseña" placeholder="Confirmar Contraseña" />
                    <ErrorMessage name="confirmarContraseña" component="div" className="mensaje-error" />
                  </div>

                  <div className="columna-formulario">
                    <Field type="text" name="nombre" placeholder="Nombres" />
                    <ErrorMessage name="nombre" component="div" className="mensaje-error" />
                    <Field type="text" name="apellido" placeholder="Apellidos" />
                    <ErrorMessage name="apellido" component="div" className="mensaje-error" />
                    <Field type="tel" name="telefono" placeholder="Teléfono: (Llamadas)" />
                    <ErrorMessage name="telefono" component="div" className="mensaje-error" />
                    <Field type="tel" name="whatsapp" placeholder="Whatsapp" />
                    <ErrorMessage name="whatsapp" component="div" className="mensaje-error" />
                    <small>Escriba de nuevo el número si es el mismo número para llamadas.</small>
                  </div>
                </div>

                {errors.general && <div className="mensaje-error">{errors.general}</div>}

                <Boton type="submit" disabled={isSubmitting} className="boton-registro">
                  {isSubmitting ? 'Registrando...' : 'Registrarse'}
                </Boton>
              </Form>
            )}
          </Formik>

          <p className="mt-3">
            ¿Necesitas registrar a un menor o como tutor? <Link to="/registro-menor-tutor">Haz clic aquí</Link>
          </p>
          <p className="enlace-inicio-sesion">
            ¿Ya tienes una cuenta? <Link to="/inicio-sesion">Inicia sesión aquí</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PantallaRegistro;
