import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import App from './App';
import { ProveedorApp } from './context/ContextoApp';
import { ProveedorTema } from './context/ContextoTema';
import LimiteErrores from './components/LimiteErrores';
import reportarMetricasWeb from './reportarMetricasWeb';
import './index.css';

// Configuración de Sentry
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: process.env.NODE_ENV
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <LimiteErrores>
      <ProveedorApp>
        <ProveedorTema>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ProveedorTema>
      </ProveedorApp>
    </LimiteErrores>
  </React.StrictMode>
);

// Si quieres empezar a medir el rendimiento en tu app, pasa una función
// para registrar los resultados (por ejemplo: reportarMetricasWeb(console.log))
// o enviar a un punto de análisis. Aprende más: https://bit.ly/CRA-vitals
reportarMetricasWeb();
