import React from 'react';
import { useTheme } from '../context/ContextoTema';
import BarraNavegacion from '../components/BarraNavegacion';
import { Tarjeta, TarjetaEncabezado, TarjetaContenido } from '../components/ui/tarjeta';
import './TerminosServicio.css'; // Asegúrate de crear este archivo CSS

const TermsOfService = () => {
  const { isDarkMode } = useTheme();

  return (
    <div className={`terms-of-service ${isDarkMode ? 'dark' : ''}`}>
      <BarraNavegacion />
      <div className="terms-container">
        <Tarjeta>
          <TarjetaEncabezado>
            <h1>Términos de Servicio</h1>
            <p>Última actualización: [Fecha]</p>
          </TarjetaEncabezado>
          <TarjetaContenido>
            <h2>Bienvenido a CitaMédica.co</h2>
            <p>Este documento establece los términos y condiciones bajo los cuales usted puede utilizar nuestra plataforma web y móvil (CitaMédica.co) para el agendamiento de citas médicas. Al acceder o utilizar nuestros servicios, usted acepta estos Términos de Servicio. Si no está de acuerdo con estos términos, debe abandonar el sitio y dejar de utilizar el servicio.</p>

            <h3>1. Definiciones</h3>
            <ul>
              <li><strong>Usuario:</strong> Toda persona o entidad que accede a la plataforma para gestionar o agendar citas médicas.</li>
              <li><strong>Cliente Institucional:</strong> Entidades del sector público o privado que se suscriben al servicio de CitaMédica.co.</li>
              <li><strong>API:</strong> Interfaz de Programación de Aplicaciones que permite la integración de nuestra plataforma con sistemas de terceros.</li>
            </ul>

            <h3>2. Uso del Servicio</h3>
            <ul>
              <li>CitaMédica.co ofrece una plataforma para que los Usuarios puedan gestionar sus citas médicas.</li>
              <li>El uso del servicio está limitado exclusivamente a las finalidades descritas. No está permitido el uso del servicio para actividades ilegales, fraudulentas o con fines de competencia desleal.</li>
            </ul>

            <h3>3. Propiedad Intelectual</h3>
            <ul>
              <li>Todos los derechos sobre el software, las aplicaciones móviles, el sitio web y cualquier otro componente de la plataforma CitaMédica.co son propiedad exclusiva de CitaMédica.co.</li>
              <li>Está prohibida la copia, distribución o creación de software basado en nuestra plataforma sin nuestra autorización expresa.</li>
            </ul>

            <h3>4. Privacidad</h3>
            <ul>
              <li>CitaMédica.co se compromete a proteger los datos personales de los usuarios. Consulte nuestra Política de Privacidad para obtener más detalles sobre cómo manejamos la información personal.</li>
            </ul>

            <h3>5. Pago y Suscripción</h3>
            <ul>
              <li>Los Clientes Institucionales deben pagar una cuota mensual por el uso del servicio. Los precios y términos de facturación se establecen en los contratos individuales con cada cliente.</li>
              <li>La falta de pago en la fecha estipulada puede resultar en la suspensión o cancelación de los servicios.</li>
            </ul>

            <h3>6. Limitación de Responsabilidad</h3>
            <ul>
              <li>CitaMédica.co no se hace responsable por daños o pérdidas derivadas del uso del servicio, errores en el agendamiento de citas, o la incapacidad de los usuarios para acceder a la plataforma.</li>
            </ul>

            <h3>7. Modificaciones a los Términos</h3>
            <ul>
              <li>CitaMédica.co se reserva el derecho de modificar estos términos en cualquier momento. Los cambios se publicarán en esta página y se notificará a los usuarios según corresponda.</li>
            </ul>

            <h3>8. Legislación Aplicable</h3>
            <ul>
              <li>Estos términos se regirán e interpretarán de acuerdo con las leyes del país en el que opera la plataforma.</li>
            </ul>

            <h3>9. Contacto</h3>
            <p>Si tiene preguntas sobre estos términos, puede contactarnos en: <a href="mailto:contact@citamedica.co">contact@citamedica.co</a>.</p>
          </TarjetaContenido>
        </Tarjeta>
      </div>
    </div>
  );
};

export default TermsOfService;
